import api from './api';
import TokenService from '../services/token.service';
import Swal from 'sweetalert2';

const setup = () => {
  api.interceptors.request.use(
    (config) => {
      let token = TokenService.getLocalAccessToken();
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (resp) => {
      if (resp.status == 200 && resp.data.success == false) {
        Swal.fire({
          icon: 'warning',
          title: 'مشکلی پیش آمده.',
          text: ' دوباره تلاش کنید',
          showConfirmButton: true,
          confirmButtonText:"تایید",
          confirmButtonColor: '#0a58ca',
        }).then(() => {
        });
        return Promise.reject(resp.data.success);
      } else if (resp.status == 200) {
        const fe_version = resp.headers['fe-version'] || 'default';
        if (
          fe_version !== localStorage.getItem('fe-version') &&
          resp.config.method == 'get'
        ) {
          localStorage.setItem('fe-version', fe_version);
          window.location.reload(); // For new version, simply reload on any get
        }
        return Promise.resolve(resp);
      }
    },
    async (err) => {
      const originalConfig = err.config;
      if (err.message == 'Network Error') {
        Swal.fire({
          icon: 'info',
          title: 'Network',
          text: 'اتصال اینترنت خود را بررسی کنید . ',
          showConfirmButton: true,
          confirmButtonColor: '#0a58ca',
          confirmButtonText:"تایید",
        });
      } else if (originalConfig.url !== '/oauth/token' && err.response) {
        // Access Token was expired
        if (err.response.status == 500) {
          Swal.fire({
            icon: 'error',
            text: 'مشکلی پیش آمده !',
            showConfirmButton: true,
            confirmButtonText:"تایید",
            confirmButtonColor: '#0a58ca',
          });
        } else if (err.response.status == 401) {
              Swal.fire({
                icon: 'info',
                title: 'دوباره وارد شوید.',
                confirmButtonColor: '#0a58ca',
                showConfirmButton: true,
                confirmButtonText:"تایید",
                customClass: 'swal-skip',
              }).then(() => {
                localStorage.setItem('logOutCheck', 'true');
                TokenService.removeUser();
                window.location.href = '/login';
              });
        } else if (err.response.status == 403) {
          Swal.fire({
            icon: 'warning',
            text: "دسترسی ندارید.",
            showConfirmButton: true,
            confirmButtonText:"تایید",
            confirmButtonColor: '#0a58ca',
            customClass: 'swal-skip',
          }).then(() => {
          });
        } else if (err.response.status == 404) {
          if (err.response.data.message) {
            const swalWithBootstrapButtons = Swal.mixin({});
            swalWithBootstrapButtons.fire({
              text: err.response.data.message,
              icon: 'warning',
              showConfirmButton: true,
              confirmButtonText:"تایید",
              confirmButtonColor: '#0a58ca',
              customClass: 'swal-warning',
            });
          } else {
            const swalWithBootstrapButtons = Swal.mixin({});
            swalWithBootstrapButtons.fire({
              text: 'موردی پیدا نشد.',
              icon: 'warning',
              showConfirmButton: true,
              confirmButtonText:"تایید",
              confirmButtonColor: '#0a58ca',
              customClass: 'swal-warning',
            });
          }
        } else {
          
          const swalWithBootstrapButtons = Swal.mixin({
          });
          let text = "";
          if(err.response.data.data){
            text = err.response.data.data[
              Object.keys(err.response.data.data)[0]
            ][0].message;
            if(text == undefined){
              text = err.response.data.data[
                Object.keys(err.response.data.data)[0]
              ][0];
            }
          }else {
            text = err.response.data.message;
          }
          swalWithBootstrapButtons.fire({
            text: text,
            icon: 'error',
            confirmButtonColor: '#0a58ca',
            showConfirmButton: true,
            confirmButtonText: 'تایید',
            customClass: 'swal-warning',
          });
        }
      }
      return Promise.reject(err);
    }
  );
};
export default setup;
