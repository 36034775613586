<template>
    <div >
    <b-navbar toggleable type="light" variant="light">
     <div class=" d-none-menu" v-b-tooltip.html title="منو" style="margin: 0 5%;cursor:">
      <router-link :to="{ name: 'home' }">
        <img
          v-if="routeName == 'home'"
          src="/img/icons/svg/chocolate-home-menu-8e8e8e.svg"
          height="35"
          alt=""
        />
        <img
          v-else
          src="/img/icons/svg/chocolate-home-menu.svg"
          height="35"
          alt=""
        />
      </router-link>
    </div>
      <div >
      <router-link class="navbar-brand m-0 p-0 " to="">
        <img
          src="@/assets/logo.png"
          alt="almas"
          id="logo"
          class="logo-header"
          width="150"
        />
      </router-link>
      </div>
      <div class="d-flex align-items-center header-position-profile-logout" >
        <div  v-b-tooltip.html title="حساب کاربری" > <router-link :to="{name:'profile'}"> {{userName}}&nbsp;</router-link></div>
        <div  v-b-tooltip.html title="خروج">
        <img   style="margin: 0 3.3%;cursor: pointer;" @click="logOut()" src="/img/icons/svg/user-leave.svg" alt="logout" width="30" >
        </div>
      </div>


      <div id="display-date-time" >
        <div class="d-flex"> 
            <div class="display-time"  style="width: 60px;"></div>
          <span>&nbsp;&nbsp;</span>
          <div class="display-date">
          </div>
        </div>
        <span>&nbsp;&nbsp;</span>
        <div>
          <div v-if="routeName != 'home' "  onclick="history.back()" style="cursor: pointer;" class="back-button">
            <span>بازگشت</span> 
            <img src="/img/icons/svg/back-button.svg" alt="" width="9"> 
          </div> 
      </div>
      </div>
    </b-navbar>

  </div>
</template>

<script>
import AdminUserNavBar from "./AdminUserNavBar.vue";
import ManagerUserNavBar from "./ManagerUserNavBar.vue";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  props: {},
  name: "App",
  components: {
    AdminUserNavBar,
    ManagerUserNavBar,
  },
  mounted(){
    const displayTime = document.querySelector(".display-time");
    // Time
    function showTime() {
      let time = new Date();
      displayTime.innerText = time.toLocaleTimeString("fa-IR", { hour12: false });
      setTimeout(showTime, 1000);
    }

    showTime();

    // Date
    function updateDate() {
      let today = new Date().toLocaleDateString('fa-IR');
      const displayDate = document.querySelector(".display-date");

      displayDate.innerText = today;

    }

    updateDate();
  },
  computed: {
    ...mapGetters({
      userRole: ["user/userRole"],
      userName: ['user/userName'],
    }),
    routeName(){
      return this.$route.name
    }
  },
  data() {
    return {
      variant: "light",
    };
  },
  methods: {
    logOut() {
      Swal.fire({
        title: 'خارج می شوید؟',
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "rgb(12, 92, 168)",
        cancelButtonColor: "#a80e0e",
        confirmButtonText: "بله",
        cancelButtonText: "انصراف",
      }).then((result) => {
        if (result.isConfirmed) {
            this.$store.dispatch("auth/logout", []).then(
            () => {
              this.successful = true;
            },
            () => {
              this.successful = false;
              this.$router.go("/");
            })
        }
      });      
    },
  },
};
</script>

<style>
.header-position-profile-logout{
  position: relative;
  left: 3%;
}
nav a.router-link-exact-active {
color: unset !important;
}
.back-button span {
  font-size: 13px;
}
.back-button img {
  margin-right: 5px;
}
@media screen and (max-width:800px) {
  .back-button{
    position: absolute;
    left: 9px;
    top: 45px;
  }
  .back-button span{
    display: none;
  }
}
#brand-logo {
  width: 50%;
  margin: 0px 25%;
  text-align: center;
}
#display-date-time{
  display: grid;
  justify-content: end;
  margin: 80px 3.3%;
  color: black;
  justify-items: center;
  position: absolute;
  left: 0;
  top: 0;
}
.b-dropdown {
  background-color: #fff !important;
}
.display-user-name {
  margin-left: 3%;
  margin-top: 15px;
  float: left;
}
.logout-labor {
  display: inline-block;
  margin-top: 10px;
}
.user-name {
  display: inline-block;
  margin-top: 5px;
  margin-left: 10px;
  color: #023517;
  font-weight: bold;
}
@media screen and (max-width:800px) {
  .d-none-menu{
    display: none;
  }  
}
</style>
