import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vSelect from "vue-select";

import setupInterceptors from './services/setupInterceptors';
setupInterceptors();

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

// Import Bootstrap and BootstrapVue CSS files (order is important);
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '../src/assets/css/global-style.css';

Vue.use(require('vue-moment'));
Vue.use(require('moment-jalaali'));
 
import VuePersianDatetimePicker from 'vue-persian-datetime-picker';
Vue.component('date-picker', VuePersianDatetimePicker);


// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.component("v-select", vSelect);

require('./plugin/vee-validate');

Vue.config.productionTip = false;

// show image full-size
import VueImg from 'v-img';
const vueImgConfig = {
  // Use `alt` attribute as gallery slide title
  altAsTitle: true,
  // Display 'download' button near 'close' that opens source image in new tab
  sourceButton: true,
  // Event listener to open gallery will be applied to <img> element
  openOn: 'click',
  // Show thumbnails for all groups with more than 1 image
  thumbnails: true,
}
Vue.use(VueImg, vueImgConfig);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
