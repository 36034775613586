<template>
  <div id="icon-menu-check">
    <b-navbar-toggle v-b-toggle.sidebar-backdrop right>
      <img
        src="/img/icons/svg/line_horizontal_filled_icon_200524_fff.svg"
        height="30"
        alt=""
      />
    </b-navbar-toggle>
    <div>
      <b-sidebar
        id="sidebar-backdrop"
        backdrop-variant="dark"
        backdrop
        shadow
        b-sidebar-right
        right
      >
        <sidebarHeader></sidebarHeader>
        <div class="py-2">
          <b-navbar-nav vertical>
            <b-nav-item-dropdown>
              <template #button-content> &nbsp;&nbsp;&nbsp; فروشندگان </template>
              <b-dropdown-item
                :to="{ name: 'companyList' }"
                class="dropdown-link-hover"
              >
                لیست فروشندگان
              </b-dropdown-item>
              <b-dropdown-item
                class="dropdown-link-hover"
                :to="{ name: 'createCompany' }"
              >
                 ایجاد فروشنده
              </b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-item-dropdown>
              <template #button-content>
                &nbsp;&nbsp;&nbsp; مشتریان
              </template>
              <b-dropdown-item
                class="dropdown-link-hover"
                :to="{ name: 'agentList' }"
              >
                لیست مشتریان
              </b-dropdown-item>
              <b-dropdown-item
                :to="{ name: 'createAgent' }"
                class="dropdown-link-hover"
              >
                ایجاد مشتری 
              </b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown>
              <template #button-content>
                &nbsp;&nbsp;&nbsp; فاکتور
              </template>
              <b-dropdown-item
                class="dropdown-link-hover"
                :to="{ name: 'InvoiceList' }"
              >
                لیست فاکتورها
              </b-dropdown-item>
            </b-nav-item-dropdown>
            <b-dropdown-item
              :to="{ name: 'profile' }"
              class="dropdown-link-hover my-4"
            >
              &nbsp;&nbsp;&nbsp;&nbsp;حساب کاربری    
            </b-dropdown-item>
            <b-dropdown-item
              class="dropdown-link-hover"
              href="#"
              v-on:click.prevent="logOut()"
            >
              &nbsp;&nbsp;&nbsp;&nbsp;خروج
            </b-dropdown-item>
          </b-navbar-nav>
        </div>
        <div class="footer">
          <hr class="footer-line" />
          Version : {{ version }}
        </div>
      </b-sidebar>
    </div>
  </div>
</template>

<script>
import SidebarHeader from "./dropdownMenu/SidebarHeader.vue";

export default {
  components: {
    SidebarHeader,
  },
  data() {
    return {
      version: null,
    };
  },
  beforeMount() {
    this.version = process.env.VUE_APP_VERSION;
  },
  methods: {
    logOut: function () {
      this.$emit("send-logOut");
    },
  },
};
</script>

<style></style>
