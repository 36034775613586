<template>
    <div id="footer-mobile">
      <!-- chocolate -->
      <div id="chocolate-menu">
        <div class="chocolate-menu-icon" id="menu-left">
        </div>
        <div class="chocolate-menu-icon d-show-menu" id="menu-center">
          <router-link :to="{ name: 'home' }">
            <img
              v-if="gteRouteName == 'home'"
              src="/img/icons/svg/chocolate-home-menu-8e8e8e.svg"
              height="35"
              alt=""
            />
            <img
              v-else
              src="/img/icons/svg/chocolate-home-menu.svg"
              height="35"
              alt=""
            />
          </router-link>
        </div>
        <div class="chocolate-menu-icon" id="menu-right">
        </div>
      </div>
    </div>
</template>

<script>
  export default {
    data() {
      return {};
    },
    beforeMount() {
      this.version = process.env.VUE_APP_VERSION;
    },
    mounted() {},
    computed: {
      gteRouteName() {
        return this.$route.name;
      },
    },
  };
</script>

<style>
#footer-background {
  background: #ffffff;
  border-width: 2px 1px 0px 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 8px 8px 0px 0px;
  bottom: 0;
  width: 100%;
  min-height: 50px;
  max-height: 100px !important;
  margin-top: 50px;
}

#footer-background a {
  text-decoration: none;
}

#footer-mobile {
  background: #ffffff;
  border-width: 2px 1px 0px 1px;
  border-style: solid;
  border-color: #d9d9d9;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px !important;
  margin-top: 100px;
  display: none;
}
@media screen and (max-width:800px) {
  #footer-mobile{
    display: unset;
  }
}

#footer-mobile a {
  text-decoration: none;
}

#chocolate-menu {
  margin-top: 10px;
}

.chocolate-menu-home {
  width: 33%;
  display: inline-block;
}
.chocolate-menu-icon {
  width: 33%;
  text-align: center;
  display: inline-block;
}
#footer-version {
  width: 100%;
  height: 20px;
  margin-top: 5px;
  color: rgb(144, 145, 145);
}
@media screen and (min-width:801px) {
  .d-show-menu{
    display: none;
  }  
}
</style>
