import UserService from "../services/user.service";

export const user = {
  namespaced: true,
  state: {
    userName: "",
    userRole:null,
    userId:null,
    // userAvatar: "",
    // userCountry: "",
    // userCityName: "",
    // imageSizeValue: "",
  },
  getters: {
    userName(state) {
      return state.userName;
    },
    userId(state) {
      return state.userId;
    },
    userRole(state) {
      return state.userRole ==null ? 'loginNow': state.userRole;
    },
  },
  actions: {
    async getUserInfo({ commit }) {
      return UserService.profile().then(
        (response) => {
          const name = response.data.name;
          commit("userId",response.data.id)

          if( response.data.role.length > 0){
            const role = response.data.role[0].name;
            const userName = response.data.name;
            
            commit("userRole", role);
            commit("userName",userName)
            commit("userId",response.data.id)
          }
          commit("userName", name);
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    userName(state, userName) {
      state.userName = userName;
    },
    userId(state, userId) {
      state.userId = userId;
    },
    userRole(state, role) {
      state.userRole = role;
    },
  },
};
