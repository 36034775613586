import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'
import RolePermission from './routeRoleBase.json'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../components/login/LogPage.vue'),
    meta: {
      layout: 'blank',
      requireLogin: false,
    },
  },
  {
    path: '/seller',
    name: 'company',
    component: () => import('../components/page/Seller/SellerTemplate.vue'),
    meta: {
      layout: 'blank',
      requireLogin: false,
    },
  },
  {
    path: '/edit-seller/:id',
    name: 'editCompany',
    component: () => import('../components/page/Seller/editSeller.vue'),
    meta: {
      layout: 'blank',
      requireLogin: false,
    },
  },
  {
    path: '/create-seller',
    name: 'createCompany',
    component: () => import('../components/page/Seller/createSeller.vue'),
    meta: {
      layout: 'blank',
      requireLogin: false,
    },
  },
  {
    path: '/seller-list',
    name: 'companyList',
    component: () => import('../components/page/Seller/SellerList.vue'),
    meta: {
      layout: 'blank',
      requireLogin: false,
    },
  },
  {
    path: '/seller-show/:id',
    name: 'companyShow',
    component: () => import('../components/page/Seller/SellerShow.vue'),
    meta: {
      layout: 'blank',
      requireLogin: false,
    },
  },

  {
    path: '/customer',
    name: 'agent',
    component: () => import('../components/page/Customer/CustomerTemplate.vue'),
    meta: {
      layout: 'blank',
      requireLogin: false,
    },
  },
  {
    path: '/customer-list',
    name: 'agentList',
    component: () => import('../components/page/Customer/CustomerList.vue'),
    meta: {
      layout: 'blank',
      requireLogin: false,
    },
  },
  {
    path: '/create-customer',
    name: 'createAgent',
    component: () => import('../components/page/Customer/createCustomer.vue'),
    meta: {
      layout: 'blank',
      requireLogin: false,
    },
  },
  {
    path: '/show/customer/:id',
    name: 'showUser',
    component: () => import('../components/page/Customer/showCustomer.vue'),
    meta: {
      layout: 'blank',
      requireLogin: false,
    },
  },
  {
    path: '/edit/customer/:id',
    name: 'editUser',
    component: () => import('../components/page/Customer/EditCustomer.vue'),
    meta: {
      layout: 'blank',
      requireLogin: false,
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../components/page/Customer/profileCustomer.vue'),
  },
  {
    path: '/invoice/list',
    name: 'InvoiceList',
    component: () => import('../components/page/Invoice/invoiceList.vue'),
    meta: {
      layout: 'blank',
      requireLogin: false,
    },
  },
  {
    path: '/customer-seller/:id',
    name: 'UserAgent',
    component: () => import('../components/page/Customer/CustomerAndSeller.vue'),
  },
  {
    path: '/show/invoice/:id',
    name: 'showInvoice',
    component: () => import('../components/page/Invoice/showInvoice.vue'),
    meta: {
      layout: 'blank',
      requireLogin: false,
    },
  },
  {
    path: '/my/invoice/lists',
    name: 'myInvoice',
    component: () => import('../components/page/Invoice/myInvoice.vue'),
    meta: {
      layout: 'blank',
      requireLogin: false,
    },
  },
  {
    path: '/operator/invoice/lists',
    name: 'operatorInvoiceList',
    component: () => import('../components/page/Invoice/OperatorInvoiceList'),
    meta: {
      layout: 'blank',
      requireLogin: false,
    },
  },
  {
    path: '/operator/list',
    name: 'operatorList',
    component: () => import('../components/page/Operator/operatorList.vue'),
  },
  {
    path: '/operator/create',
    name: 'createOperator',
    component: () => import('../components/page/Operator/operatorCreate.vue'),
  },
  {
    path: '/operator/edit/:id',
    name: 'editOperator',
    component: () => import('../components/page/Operator/operatorEdit.vue'),
  },
  {
    path: '/operator/show/:id',
    name: 'operatorShow',
    component: () => import('../components/page/Operator/operatorShow.vue'),
  },
  {
    path: '/setting',
    name: 'setting',
    component: () => import('../components/page/Setting/settingTemplate.vue'),
  },
  {
    path: '/tickets/list',
    name: 'ticketList',
    component: () => import('../components/page/Ticket/ticketList.vue'),
  },
  {
    path: '/my/tickets/list',
    name: 'myTicketList',
    component: () => import('../components/page/Ticket/myTicketList.vue'),
  },
  {
    path: '/ticket/message/:id',
    name: 'TicketMessage',
    component: () => import('../components/page/Ticket/chatPage.vue'),
  },
  {
    path: '/admin/ticket/message/:id',
    name: 'AdminTicketMessage',
    component: () => import('../components/page/Ticket/chatAdmin.vue'),
  },
  {
    path: '/change-password',
    name: 'changePassword',
    component: () => import('../components/page/Customer/changePassword.vue'),
  },
  {
    path: '/import-excel',
    name: 'importExcel',
    component: () => import('../components/page/Customer/ImportCustomerExcel.vue'),
  },
  {
    path: '/logs',
    name: 'logs',
    component: () => import('../components/page/Log/LogsComponent.vue'),
  },
  {
    path: '*',
    name: 'notFound',
    component: () => import('../components/404/404Page.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  
  let access_token = localStorage.getItem('access_token');
  if (access_token) {
    let passwordNeedsChange = store.getters['auth/isPasswordNeedsChange'];
    if (passwordNeedsChange && from.name == 'changePassword') {
      next({ name: 'changePassword' });
    }
    else {
      let role=store.getters['user/userRole']
      if(RolePermission[role].pathName.includes(to.name)){
         next();
      } else if(from.name=='login'){
        next();
      }
    }
  } else {
    if (
      to.name == 'login' ||
      to.name == 'forgetpassword' ||
      to.name == 'ResetPassword'
    ) {
      next();
    } else {
      next({ name: 'login' });
    }
  }
});

export default router
