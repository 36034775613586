import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import { auth } from "./auth.module";
import { user } from "./user.module";

Vue.use(Vuex);

// js-cookie to use browser Cookies
// window.localStorage (remains, across PC reboots, untill you clear browser data)
// window.sessionStorage (vanishes when you close browser tab)
// localForage Uses IndexedDB from the browser

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ["user", "auth"],
});

const vuexSession = new VuexPersistence({
  storage: window.sessionStorage,
  modules: [""],
});

export default new Vuex.Store({
  modules: {
    user,
    auth,
  },
  plugins: [vuexSession.plugin, vuexLocal.plugin],
});
