<template>
  <div id="app">
    <div id="main">
      <div id="main-container" >
        <div v-if="isLoggedIn !='loginNow' ">
          <HeaderBar></HeaderBar>
        </div>

        <div id="main-router-view">
          <router-view />
        </div>

        <div v-if="isLoggedIn !='loginNow' ">
          <FooterBar></FooterBar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBar from "./components/layouts/HeaderBar.vue";
import FooterBar from "./components/layouts/FooterBar.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    HeaderBar,
    FooterBar,
  },
  data() {
    return {
      getNowTime: 0,
      opportunityTime: 0,
      timeLeft: 0,
      show: false,
    };
  },
  mounted() { 
    if (this.userRole == null && localStorage.getItem("access_token")) {
      this.$store.dispatch("user/getUserInfo");
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["user/userRole"];
    },
    routerName() {
      return this.$route.name;
    },
     ...mapGetters({
      userRole: ["user/userRole"],
    }),
  },

};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#main-router-view {
  display: inline-block !important;
  min-height: 75vh;
  width: 100%;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
