import api from "./api";
import TokenService from "./token.service";

const user = process.env.VUE_APP_API_URL;

class AuthService {
  login(formData) {
    return api
      .post('/login', formData)
      .then((response) => {
        TokenService.updateLocalAccessToken(response.data.access_token);
        TokenService.updateLocalRefreshToken(response.data.refresh_token);
        return Promise.resolve(response);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  verification(formData) {
    return api
      .post(user + "verify/email", formData)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  logout() {
    return api
      .post(user + "logout")
      .then((response) => {
        return response;
      })
      .catch(() =>{});
  }
}

export default new AuthService();
