import api from "./api";

class UserService {
  async create(data) {
    return api
      .post("user/save", data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  }
  async show(id) {
    return api
      .get("user/" + id)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  get(currentPage, searchValue,companyId) {
    return api.get('user/all?page=' + currentPage +(searchValue != '' ? '&keyword=' + searchValue : '')+(companyId !=null ?'&companyId='+companyId:''))
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  async update(id, data) {
    return api
      .put("user/" + id + "/update", data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  async delete(id) {
    return api
      .delete("user/" + id + "/delete")
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  async profile() {
    return api
      .get("user/getProfile")
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  async externalCompanies() {
    return api
      .get("user/getExternalCompanies")
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  async changeMyPassword(data) {
    return api
      .put("user/changeMyPassword",data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  async uploadExcel(form){
    const headers = { 'Content-Type': 'multipart/form-data' };
      return api.post('user/import',form,{ headers }).then((res)=>{
         return res.data;
      }).catch((error)=>{
         return Promise.reject(error);
      })
  }
  updateData(){
      return api.get('user/updateData',).then((res)=>{
         return res.data;
      }).catch((error)=>{
         return Promise.reject(error);
      })
  }
  
  getUserByName(keyWord){
    return api.get('user/getUsersByName'+(keyWord.length > 0 ? '?keyword='+keyWord : '')).then((res)=>{
       return res.data;
    }).catch((error)=>{
       return Promise.reject(error);
    })
}

}

export default new UserService();
