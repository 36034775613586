class TokenService {
  getLocalRefreshToken() {
    return localStorage.getItem("refresh_token");
  }

  getLocalAccessToken() {
    return localStorage.getItem("access_token");
  }

  updateLocalRefreshToken(refreshToken) {
    localStorage.setItem("refresh_token", refreshToken);
  }

  updateLocalAccessToken(accessToken) {
    localStorage.setItem("access_token", accessToken);
  }

  getUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  setOpportunityTime(opportunityTime) {
    localStorage.setItem("opportunityTime", opportunityTime);
  }

  setUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
  }

  removeUser() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("vuex");
  }
}

export default new TokenService();
